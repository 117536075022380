<template>
  <div>
    <c-table
      ref="grid2"
      class="q-mt-md"
      title="개선요청"
      :columns="grid2.columns"
      :data="imprs"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :isTitle="true"
      :editable="editable&&!disabled"
      @linkClick="imprlinkClick"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addImpr" />
        </q-btn-group>
      </template>
    </c-table>
    <c-table
      ref="grid1"
      title="즉시조치"
      :columns="grid.columns"
      :data="imprImms"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :isTitle="true"
      :editable="editable&&!disabled"
      @linkClick="immlinkClick"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addImprImm" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fireFightingImpr',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingCheckId: '',
      }),
    },
    check: {
      type: Object,
      default: () => ({
        sopFireFightingCheckId: '',  // 소방설비 점검 일련번호
        plantCd: '',  // 사업장
        sopFireFightingCheckTypeCd: null,  // 점검유형
        checkName: '',  // 점검명
        sopFireFightingCheckStepCd: '',  // 점검진행상태
        checkStartDate: '',  // 점검시작일
        checkEndDate: '',  // 점검종료일
        period: [],
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        targets: [],
        deleteTargets: [],
        imprs: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
        ],
      },
      grid2: {
        columns: [
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
        ],
      },
      imprData: null,
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '100%',
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return this.contentHeight ? String((this.contentHeight / 2) - 50) + 'px' : '500px';
    },
    imprs() {
      return this.$_.filter(this.check.imprs, { ibmClassCd: 'IC00000001' })
    },
    imprImms() {
      return this.$_.filter(this.check.imprs, { ibmClassCd: 'IC00000005' })
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.fft.fireFightingCheck.impr.url
      // code setting
      // list setting
    },
    getImprs() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        sopFireFightingCheckId: this.popupParam.sopFireFightingCheckId,
      }
      this.$http.request((_result) => {
        this.$set(this.check, 'imprs', _result.data)
      },);
    },
    addImprImm() {
      this.popupOptions.title = '즉시조치'; // 즉시조치
      this.popupOptions.param = {
        requestContents: this.check.checkName,
        relationTableKey: this.check.sopFireFightingCheckId,
        ibmTaskTypeCd: 'ITT0000180',
        ibmTaskUnderTypeCd: 'ITTU000205',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    addImpr() {
      this.popupOptions.title = '개선 요청';
      this.popupOptions.param = {
        requestContents: this.check.checkName,
        relationTableKey: this.check.sopFireFightingCheckId,
        ibmTaskTypeCd: 'ITT0000180',
        ibmTaskUnderTypeCd: 'ITTU000205',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImprs();
    },
    /* eslint-disable no-unused-vars */
    immlinkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = '즉시조치 상세'; // 즉시조치 상세
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImmDetailPopup;
    },
    closeImmDetailPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (flag !== 'delete') {
          this.$_.extend(this.imprData, result.col1)
        } else {
          this.check.imprs = this.$_.reject(this.check.imprs, { sopImprovementId: result.col1.sopImprovementId })
        }
      }
    },
    imprlinkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = '개선';
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprDetailPopup;
    },
    closeImprDetailPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (flag !== 'delete') {
          this.$_.extend(this.imprData, result.col1)
        } else {
          this.check.imprs = this.$_.reject(this.check.imprs, { sopImprovementId: result.col1.sopImprovementId })
        }
      }
    },
  }
};
</script>